<template>
	<div class="animateSections">
		<section class="fullPageSectionContainer setMenuColor" data-menu-color="light">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #262E39"></div>
				<Banner :content="banner" :titleIsH1="true"></Banner>
			</div>
		</section>
		<section class="fullPageSectionContainer fullHeightContent setMenuColor" data-menu-color="dark">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #FAFAFA"></div>
				<ProjectsSlider></ProjectsSlider>
			</div>
		</section>
		<section class="fullPageSectionContainer setMenuColor" data-menu-color="light">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #262E39"></div>
				<Awards :content="awards"></Awards>
			</div>
		</section>
		<section class="fullPageSectionContainer setMenuColor" data-menu-color="dark">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #FAFAFA"></div>
				<TrustCompanies :content="trustCompanies"></TrustCompanies>
			</div>
		</section>
		<section class="fullPageSectionContainer setMenuColor" data-menu-color="light">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #262E39"></div>
				<Technologies :content="technologies" class="color-white"></Technologies>
			</div>
		</section>
		<section class="fullPageSectionContainer setMenuColor" data-menu-color="dark">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #FAFAFA"></div>
				<Command :content="command"></Command>
			</div>
		</section>
		<section class="fullPageSectionContainer setMenuColor" id="feedBackForm" data-menu-color="light">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #262E39"></div>
				<Feedback></Feedback>
			</div>
		</section>
	</div>
</template>

<script>

	export default {
		name: 'home',
		metaInfo: {
			title: 'Артрокетс – веб-студия из Казани 🚀',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Веб-студия Артрокетс: профессиональная разработка сайтов, техническая поддержка. Индивидуальный подход к каждому клиенту, бессрочная гарантия на выполненные работы. Оставьте заявку на сайте, и мы свяжемся с вами!' },
				{ vmid: 'og:title', property: 'og:title', content: 'Артрокетс – веб-студия из Казани 🚀' },
				{ vmid: 'og:description', property: 'og:description', content: 'Веб-студия Артрокетс: профессиональная разработка сайтов, техническая поддержка. Индивидуальный подход к каждому клиенту, бессрочная гарантия на выполненные работы. Оставьте заявку на сайте, и мы свяжемся с вами!' },
			],
		},
		data: () => ({
			banner: {
				title: "Digital-студия чистого кода",
				desc: "Артрокетс специализируется на разработке сложно-функциональных сайтов, веб-сервисов, порталов, интернет-магазинов и мобильных приложений."
			},
			awards: {
				title: 'Работаем с <b class="color-white">финансовым сектором, страховыми компаниями, застройщиками,  производителями</b> и <b class="color-white">ритейлом</b>.',
				items: [
					{
						icon: '<span class="icon-pointer"></span>',
						title: 'в рейтинге разработчиков интернет-магазинов Казани 2020 в верхнем ценовом сегменте',
						desc: '1ое место среди'
					},{
						icon: '<span class="icon-bitrix"></span>',
						title: '1С-Битрикс с 2017 года',
						desc: 'Золотой партнер'
					},{
						icon: '<span class="icon-golden"></span>',
						title: 'премии “Золотой сайт банка или страховой компании” 2019 г',
						desc: 'Серебряный призер'
					},{
						icon: '<span class="icon-raek"></span>',
						title: 'члены кластера РАЭК',
						desc: 'C 2020 года'
					}
				]
			},
			trustCompanies: {
				title: "Клиенты",
				desc: "Гордимся и дорожим нашими клиентами, трепетно относимся к каждому проекту.",
				items: [
					{
						logo: require("@/assets/img_new/companies/abd.png")
					},{
						logo: require("@/assets/img_new/companies/dymov.png")
					},{
						logo: require("@/assets/img_new/companies/kaplife.png")
					},{
						logo: require("@/assets/img_new/companies/rgs-life.png")
					},{
						logo: require("@/assets/img_new/companies/agrosila.png")
					},{
						logo: require("@/assets/img_new/companies/pascal.png"),
						additionalClass: "hide600"
					},{
						logo: require("@/assets/img_new/companies/centrserdce.png"),
						additionalClass: "hide600"
					},{
						logo: require("@/assets/img_new/companies/rgs-med.png")
					},{
						logo: require("@/assets/img_new/companies/medar.png")
					},{
						logo: require("@/assets/img_new/companies/fin-trans.png")
					},{
						logo: require("@/assets/img_new/companies/sberfond.png")
					},{
						logo: require("@/assets/img_new/companies/larisa.png"),
						additionalClass: "hide600"
					},{
						logo: require("@/assets/img_new/companies/technorosst.png")
					},{
						logo: require("@/assets/img_new/companies/savage.png"),
						additionalClass: "hide600"
					},{
						logo: require("@/assets/img_new/companies/mpkabel.png"),
						additionalClass: "hide600"
					}
				]
			},
			technologies: {
				title: "Технологии",
				desc: "В разработке мы используем различный stack технологий и методологий разработки, которые подбираются для каждого проекта индивидуально.",
				items: [
					{
						title: "Front-end",
						technologies: [
							"React",
							"Angular",
							"Vue",
							"JQuery"
						]
					},{
						title: "Back-end",
						technologies: [
							"Php",
							"Bitrix",
							"Python",
							"Yii2"
						]
					},{
						title: "Management",
						technologies: [
							"SCRUM",
							"Agile",
							"Waterfall"
						]
					},{
						title: "Mobile",
						technologies: [
							"Swift",
							"Kotlin",
							"Flutter"
						]
					}
				]
			},
			command: {
				title: "Инхаус команда",
				desc: "Все специалисты в команде у нас в штате. Это обеспечивает стабильное качество услуг и соблюдение сроков.",
				image: require("@/assets/img_new/command.jpg"),
				percents: [
					{
						num: "50+",
						desc: "уникальных проектов разработано «под ключ»"
					},{
						num: "20+",
						desc: "проектов на регулярной поддержке"
					},{
						num: "15",
						desc: "профильных специалистов в команде"
					},{
						num: "4",
						desc: "года стабильной работы на рынке"
					}
				]
			}
		}),
		components: {
			Banner: () => import('@/components/new/Banner'),
			ProjectsSlider: () => import('@/components/new/ProjectsSlider'),
			Awards: () => import('@/components/new/Awards'),
			TrustCompanies: () => import('@/components/new/TrustCompanies'),
			Technologies: () => import('@/components/new/Technologies'),
			Command: () => import('@/components/new/Command'),
			Feedback: () => import('@/components/new/Forms/Feedback')
		}
	}
</script>
